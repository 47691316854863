<template>
    <v-card flat>
        <v-card-title>
            <span class="primary--text font-weight-bold title mb-4">Add New Organization</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <h4>Name of the organization</h4>
                <v-text-field
                    v-model="orgName"
                    :counter="50"
                    label="Provide a name for your organization"
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="loading"
                ></v-text-field>
                <ShortIdInput :disabledEdit="loading" :longId="orgName" @input="updateShortId($event.nextValue)"></ShortIdInput>
                <h4 class="mt-4">Description of the organization</h4>
                <v-textarea
                    v-model="orgDescription"
                    label="Provide a description for your organization"
                    rows="4"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="loading"
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <div class="d-flex flex-column align-center" style="width:100%">
                <div class="d-flex align-center">
                    <v-btn :loading="loading" :disabled="!valid || loading" @click="addOrg()" color="secondary" class="mr-1">
                        <v-icon small>add</v-icon>Add Organization
                    </v-btn>
                    <v-btn :to="{ name: 'admin-overview' }" color="secondary" outlined text exact :disabled="loading">cancel</v-btn>
                </div>
                <v-alert max-width="1000" :value="$data.error" color="error" icon="warning" class="mt-4" text>
                    <span class="font-weight-bold">{{ errorContent }}</span>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import ShortIdInput from '@/components/ShortIdInput'

export default {
    data() {
        return {
            orgName: '',
            noOrgName: false,
            orgShortName: '',
            orgDescription: '',
            noOrgDescription: false,
            error: false,
            errorContent: 'AN ERROR HAS OCCURRED',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            },
            loading: false,
            valid: false
        }
    },
    components: {
        ShortIdInput
    },
    methods: {
        updateShortId(newShortId) {
            this.$data.orgShortName = newShortId
        },
        addOrg: function() {
            this.$data.error = false
            this.$data.noOrgName = false
            this.$data.noOrgDescription = false
            const apiURL = '/orgs'
            if (this.$data.orgName === '') {
                this.$data.noOrgName = true
            }
            if (!this.$data.orgDescription) {
                this.$data.noOrgDescription = true
            }
            if (!this.$data.noOrgName && !this.$data.noOrgDescription) {
                this.$data.loading = true
                const postBody = {
                    description: this.$data.orgDescription,
                    long_id: this.$data.orgName,
                    short_id: this.$data.orgShortName
                }
                this.$axios
                    .post(apiURL, postBody, { timeout: 300000 })
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Organization created successfully',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$store.dispatch('fetchUserOrgs', false)
                        this.$router.push({ name: 'admin-overview' })
                    })
                    .catch(error => {
                        this.$data.error = true
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "org_long_id_key"')
                        ) {
                            this.$data.errorContent = 'The short name you chose for this organization is already in use, please choose another name'
                        }
                    })
                    .finally(() => {
                        this.$data.loading = false
                    })
            }
        }
    }
}
</script>
