<template>
    <v-text-field v-if="editableMode" autofocus persistent-hint v-model="shortId" append-icon="save" v-on:click:append="saveUserProvidedShortId"></v-text-field>
    <div v-else class="d-flex align-center">
        <span class="primary--text font-weight-bold">Short name:</span>
        <v-tooltip left class="mr-1">
            <template v-slot:activator="{ on }">
                <v-icon small v-on="on">help_outlined</v-icon>
            </template>
            <span>A short-name identifies the object in a database or the file-system.</span>
        </v-tooltip>
        {{ shortId }}
        <v-icon v-if="false" @click="enableEditShortId">edit</v-icon>
    </div>
</template>

<script>
import { makeShortId } from '../utils'
export default {
    name: 'ShortIdInput',
    data() {
        return {
            editableMode: false,
            shortId: this.generateShortId(this.$props.longId)
        }
    },
    props: {
        longId: String,
        disabledEdit: { type: Boolean, default: false }
    },
    methods: {
        generateShortId(longId) {
            return makeShortId(longId)
        },
        enableEditShortId: function() {
            this.editableMode = true
        },
        saveUserProvidedShortId: function() {
            this.shortId = this.generateShortId(this.shortId)
            this.editableMode = false
        }
    },
    watch: {
        longId: function(nextValue, prevValue) {
            this.$data.shortId = this.generateShortId(nextValue)
        },
        shortId: function(nextValue, prevValue) {
            this.$emit('input', { nextValue: nextValue })
        }
    },
    mounted() {
        this.$emit('input', { nextValue: this.$data.shortId })
    }
}
</script>
