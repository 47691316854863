var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editableMode
    ? _c("v-text-field", {
        attrs: { autofocus: "", "persistent-hint": "", "append-icon": "save" },
        on: { "click:append": _vm.saveUserProvidedShortId },
        model: {
          value: _vm.shortId,
          callback: function($$v) {
            _vm.shortId = $$v
          },
          expression: "shortId"
        }
      })
    : _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("span", { staticClass: "primary--text font-weight-bold" }, [
            _vm._v("Short name:")
          ]),
          _c(
            "v-tooltip",
            {
              staticClass: "mr-1",
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c("v-icon", _vm._g({ attrs: { small: "" } }, on), [
                        _vm._v("help_outlined")
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "A short-name identifies the object in a database or the file-system."
                )
              ])
            ]
          ),
          _vm._v(" " + _vm._s(_vm.shortId) + " "),
          false
            ? _c("v-icon", { on: { click: _vm.enableEditShortId } }, [
                _vm._v("edit")
              ])
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }