var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "span",
          { staticClass: "primary--text font-weight-bold title mb-4" },
          [_vm._v("Add New Organization")]
        )
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("h4", [_vm._v("Name of the organization")]),
              _c("v-text-field", {
                attrs: {
                  counter: 50,
                  label: "Provide a name for your organization",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.orgName,
                  callback: function($$v) {
                    _vm.orgName = $$v
                  },
                  expression: "orgName"
                }
              }),
              _c("ShortIdInput", {
                attrs: { disabledEdit: _vm.loading, longId: _vm.orgName },
                on: {
                  input: function($event) {
                    return _vm.updateShortId($event.nextValue)
                  }
                }
              }),
              _c("h4", { staticClass: "mt-4" }, [
                _vm._v("Description of the organization")
              ]),
              _c("v-textarea", {
                attrs: {
                  label: "Provide a description for your organization",
                  rows: "4",
                  "auto-grow": "",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.orgDescription,
                  callback: function($$v) {
                    _vm.orgDescription = $$v
                  },
                  expression: "orgDescription"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-actions", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column align-center",
            staticStyle: { width: "100%" }
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      loading: _vm.loading,
                      disabled: !_vm.valid || _vm.loading,
                      color: "secondary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addOrg()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v("Add Organization ")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      to: { name: "admin-overview" },
                      color: "secondary",
                      outlined: "",
                      text: "",
                      exact: "",
                      disabled: _vm.loading
                    }
                  },
                  [_vm._v("cancel")]
                )
              ],
              1
            ),
            _c(
              "v-alert",
              {
                staticClass: "mt-4",
                attrs: {
                  "max-width": "1000",
                  value: _vm.$data.error,
                  color: "error",
                  icon: "warning",
                  text: ""
                }
              },
              [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.errorContent))
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }